import { SHARED_COLORS } from '@ess/shared/utils/consts';
import { essDaysDifference, essFormatDateToISO } from '@ess/shared/utils/helpers';
import {
  SharedChartInterval,
  SharedLineChartMarkPoint,
  SharedLineChartMarkPointData,
  SharedTimespan,
} from '@ess/shared/utils/models';

import { ISApproval, ISApprovals, ISRecommendation } from '../models';

interface IntervalWithApprovals<R extends ISRecommendation> extends SharedChartInterval {
  approvals: ISApproval<R>[];
}

export class ISTacticsApprovalsHelper {
  static mapApprovalsToChartMarkPoint<R extends ISRecommendation>(
    approvals: ISApprovals<R>[],
    timespan: SharedTimespan,
  ): SharedLineChartMarkPoint {
    const intervals: SharedChartInterval[] = this.__getIntervals(timespan);
    const sortedApprovals = approvals
      .filter((series) => series.recommendations.length > 0)
      .sort((a, b) => (a.date < b.date ? -1 : 1));
    sortedApprovals.forEach((a) => a.recommendations.forEach((r) => (r.date = a.date)));
    const intervalsWithApprovals: IntervalWithApprovals<R>[] = intervals.map((interval) => {
      const lastFromInterval = sortedApprovals.findIndex((approval) => approval.date > interval.end);
      return {
        ...interval,
        approvals: sortedApprovals
          .splice(0, lastFromInterval >= 0 ? lastFromInterval : sortedApprovals.length)
          .flatMap((a) => a.recommendations)
          .map((r) => ({ ...r, interval })),
      };
    });

    return {
      data: intervalsWithApprovals
        .filter((i) => i.approvals.length > 0)
        .map<SharedLineChartMarkPointData>((interval: IntervalWithApprovals<R>) => ({
          value: interval.approvals.length,
          symbol: 'roundRect',
          symbolSize: 20,
          coord: [interval.approvals.length === 1 ? interval.approvals[0].date : interval.pointer, '0'],
          name: 'Recommendations approved',
          markPointData: interval.approvals,
          label: {
            color: SHARED_COLORS['neutral-variant']['900'],
          },
          itemStyle: {
            color: 'white',
            borderColor: SHARED_COLORS.priority.paused,
            borderWidth: 1,
          },
          emphasis: {
            itemStyle: {
              color: SHARED_COLORS.paused['50'],
            },
            label: {
              color: SHARED_COLORS['neutral-variant']['900'],
            },
          },
        })),
    };
  }

  private static __getIntervalLength(timespan: SharedTimespan): number {
    const range: number = essDaysDifference(new Date(timespan.start_date), new Date(timespan.end_date)) + 1;
    if (range <= 7) {
      return 1;
    } else if (range <= 31) {
      return 7;
    } else if (range <= 90) {
      return 14;
    } else if (range <= 180) {
      return 30;
    } else {
      return 60;
    }
  }

  private static __getIntervals(timespan: SharedTimespan): SharedChartInterval[] {
    const interval: number = this.__getIntervalLength(timespan);
    const pointerDate: Date = new Date(timespan.start_date);
    const endDate: Date = new Date(timespan.end_date);
    const intervals: SharedChartInterval[] = [];
    while (pointerDate.getTime() <= endDate.getTime()) {
      let end = new Date(pointerDate);
      end.setDate(end.getDate() + interval - 1);
      end = end.getTime() < endDate.getTime() ? end : new Date(endDate);
      const pointer = new Date(pointerDate);
      const middle = Math.floor(essDaysDifference(pointerDate, end) / 2);
      pointer.setDate(pointer.getDate() + middle);
      intervals.push({
        start: essFormatDateToISO(pointerDate),
        end: essFormatDateToISO(end),
        pointer: essFormatDateToISO(pointer),
      });
      pointerDate.setDate(pointerDate.getDate() + interval);
    }
    return intervals;
  }
}
